.dashboard {
  position: relative;
  width: 100%;
}

.select-group-container {
  position: relative;
  z-index: 10;
  width: 100%;
  padding-right: 270px;
  background: #fff;
  &.flex-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
}

.chart-card-container {
  margin-bottom: 6px;

  .chart-card {
    margin-top: 6px;
    margin-left: 8px;
    padding: 14px;
    background: #fff;
  }
  .chart-item:nth-child(4n + 1) .chart-card {
    margin-left: 0px;
  }
}

.table-container {
  .action-bar-container {
    z-index: 80;
    margin-right: 50px;
    padding: 8px;
    font-size: 20px;
    text-align: right;
  }
  .cmcty-table-thead > tr > th {
    text-align: center;
  }
  .cmcty-table-pagination {
    position: absolute;
    top: -50px;
    left: 720px;
    display: inline-block;
  }
}

.pagination-l-300 {
  .cmcty-table-pagination {
    position: absolute;
    top: -45px;
    left: 300px;
    display: inline-block;
  }
}

.tab-header {
  &.cmcty-tabs-top > .cmcty-tabs-nav::before {
    border: none;
  }
  & > .cmcty-tabs-nav {
    position: absolute;
    z-index: 80;
    /* // min-width: 50%; */
    padding: 6px 0;
    .cmcty-tabs-tab {
      padding: 4px 12px;
      border-radius: 8px 8px 0 0;
      .cmcty-tabs-tab-btn {
        min-width: 100px;
        text-align: center;
      }
      /* // &.cmcty-tabs-tab-active {
      //   background: @primary-color;
      //   .cmcty-tabs-tab-btn {
      //     color: #fff;
      //   }
      // } */
    }
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.partitionOutlined {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

&.tree-modal.cmcty-modal {
  top: 50px;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.scroll-action {
  --actionWidth: 40px;
  z-index: 10;
  display: inline-block;
  width: var(--actionWidth);
  height: 100%;
  text-align: center;
  .cmcty-btn {
    padding: 0;
  }
  .anticon {
    font-size: var(--actionWidth);
  }
  &:first-child {
    margin-right: -40px;
  }
  &:last-child {
    margin-left: -40px;
  }
}

.scroll-context {
  display: inline-block;
  width: 100%;
}

.card-container {
  height: 100%;
  overflow: hidden;
}

.card-outer-container {
  height: 100%;
  overflow: hidden;
}

.card-scroll-container {
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.card-scroll-item {
  display: block;
  float: left;
  height: 100%;
  padding: 12px;
}

.card-scroll-item.border {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.simulation {
  display: flex;
  white-space: nowrap;
  span {
    margin-top: 4px;
  }
}

.selector {
  display: flex;
  white-space: nowrap;
  span {
    margin-top: 4px;
  }
}

.green {
  color: green;
}
.red {
  color: red;
}

@keyframes bg-blink {
  0% {
    background: transparent;
  }
  33.33% {
    background: rgba(255, 0, 0, 0.3);
  }
  66.66% {
    background: transparent;
  }
  100% {
    background: rgba(255, 0, 0, 0.3);
  }
}

.modal-table-container {
  margin-top: 10px;
  padding: 0 50px;
}

.btn-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  div {
    margin: 10px;
  }
}

.block-item {
  height: 65px;
  line-height: 65px;
}

.red-text {
  color: red;
}

.view-switcher {
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline-block;
}

.cmcty-table-thead {
  .border-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-right-0 {
    border-right: 0 !important;
  }
}

.cmcty-table-cell:has(.fa-grey),
.cmcty-table-tbody > tr.cmcty-table-row:hover > .cmcty-table-cell:has(.fa-grey) {
  background: #efefef;
}

.chart-table-container {
  .ant-table-body {
    overflow: auto hidden !important;
  }
  &.small-view {
    .ant-table-cell {
      height: 20px !important;
      padding: 0 !important;
      font-size: 12px;
    }
    .ant-table-body {
      .ant-table-cell {
        padding: 0 8px !important;
      }
    }
  }
  &.big-view {
    .ant-table-cell {
      height: 22px !important;
      padding: 0 8px !important;
      font-weight: bolder;
      font-size: 20px;
    }
  }
  &.overall-view {
    .ant-table-cell {
      height: 30px !important;
      padding: 0 8px !important;
      font-size: 16px;
    }
  }
}

.ribbon {
  position: absolute;
  top: 15px;
  right: 0;
  display: inline-block;
  &.small-view {
    &:before {
      position: absolute;
      left: -1px;
      z-index: 1;
      border-top: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 11px solid white;
      content: '';
    }
    span {
      position: relative;
      display: inline-block;
      padding: 0 22px;
      font-size: 12px;
      line-height: 22px;
      -webkit-transition: background-color 0.2s, margin-top 0.2s; /* Saf3.2+, Chrome */
      -moz-transition: background-color 0.2s, margin-top 0.2s; /* FF4+ */
      -ms-transition: background-color 0.2s, margin-top 0.2s; /* IE10 */
      -o-transition: background-color 0.2s, margin-top 0.2s; /* Opera 10.5+ */
      transition: background-color 0.2s, margin-top 0.2s;
    }
  }
  &.big-view {
    &:before {
      position: absolute;
      left: 0;
      z-index: 1;
      border-top: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid white;
      content: '';
    }
    span {
      position: relative;
      display: inline-block;
      padding: 0 36px;
      font-size: 18px;
      line-height: 36px;
      -webkit-transition: background-color 0.2s, margin-top 0.2s; /* Saf3.2+, Chrome */
      -moz-transition: background-color 0.2s, margin-top 0.2s; /* FF4+ */
      -ms-transition: background-color 0.2s, margin-top 0.2s; /* IE10 */
      -o-transition: background-color 0.2s, margin-top 0.2s; /* Opera 10.5+ */
      transition: background-color 0.2s, margin-top 0.2s;
    }
  }
}

.chart-group-header {
  position: relative;
  margin-top: 3px;
  padding: 4px 24px;
  background-color: #fff;
  .chart-group-header-title {
    font-weight: bold;
    font-size: 18px;
  }
}
