.portfolio-monitor {
  .cmcty-slider-with-marks {
    margin: 8px;
  }

  .portfolio-table {
    .cmcty-table-thead > tr > th {
      text-align: center;
    }

    .align-right {
      text-align: right;
    }

    .align-left {
      text-align: left;
    }

    .align-center {
      text-align: center;
    }

    .cmcty-table-pagination-right {
      margin: 8px 12px;
    }
  }

  .custom-scrollbar {
    span:not(.cmcty-select-arrow, .cmcty-select-clear) {
      display: block;
      padding-right: 8px;
      text-align: right;
    }
  }

  .padding_0_8 {
    padding: 0 8px !important;
  }
}
