/* stylelint-disable rule-empty-line-before */
.memo-container {
  .cmcty-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .cmcty-modal-header {
      padding-bottom: 8px;
    }
    .cmcty-modal-body {
      height: calc(100% - 74px);
      padding: 0;
      .cmcty-spin-nested-loading {
        height: 100%;
        .cmcty-spin-container {
          height: 100%;
          .ChatApp {
            background: #fff;
            .Composer-inputWrap {
              .Composer-input {
                /* font-size: small; */
                caret-color: #1a4f99;
              }
            }
            .Message-inner {
              display: flex;
              flex-direction: column;
              padding: 0 12px;
              .Message-content {
                flex-direction: row;
                & > div {
                  width: 100%;
                }
                .bubble-not-self {
                  .Bubble {
                    background: rgba(176, 206, 219, 0.6);
                  }
                }
                .Bubble {
                  width: calc(100% - 50px);
                  margin-right: 10px;
                  padding: 6px;
                  background: rgba(250, 216, 96, 0.6);
                  border-radius: 6px;

                  .extra {
                    margin-top: 5px;
                    color: grey;
                    font-size: smaller;
                    font-style: italic;
                    line-height: 105%;
                  }
                }
              }
            }
            .Composer-sendBtn {
              background: #3299d9;
            }
          }
        }
      }
    }
  }
}
