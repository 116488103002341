.trinity-evaluation {
  .cmcty-table-tbody {
    tr:first-child {
      td {
        position: relative;
        color: #373737;
        font-weight: 500;
        text-align: center !important;
        background: #fafafa;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        transition: background 0.3s ease;
      }
    }
  }
  .chart-section {
    margin-top: 3px;
  }
  .display-gray {
    opacity: 0.7;
    filter: blur(2px) grayscale(0.8);
  }

  .square::before,
  .square::after,
  .square::first-line {
    position: absolute;
    width: 142%; /* 对角线长度 */
    height: 2px;
    background-color: white;
    transform: rotate(45deg);
    transform-origin: top left;
    content: '';
  }

  .square {
    position: relative;
  }

  .square::before,
  .square::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    transform: rotate(45deg);
    content: '';
  }
  .square::before {
    top: 5px;
    left: 13px;
    width: 2px; /* 直线宽度 */
    height: 100%;
  }
  .square::after {
    left: 8px;
    width: 2px; /* 直线宽度 */
    height: 100%;
  }
}

.trinity-comparison-table-area {
  .trinity-comparison-table {
    .title-warp {
      white-space: pre-wrap;
    }
    .children-left-border {
      border-right-color: #838282 !important;
    }
    .children-left {
      border-right-width: 0px !important;
    }
    .cmcty-table-thead th {
      text-align: center;
    }
    .cmcty-table-tbody {
      .cmcty-table-row {
        .cmcty-table-cell:nth-child(n + 2) {
          text-align: right;
        }
      }
      .count-row {
        font-weight: bolder;
        background-color: #e5e5e5;
      }
      .bottom-line {
        td {
          border-bottom: 1px #838282 solid;
        }
      }
    }
  }
}
