@import './modal-memo.css';

:root {
  --header-height: 50px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

body {
  background-color: #f0f2f5;
}

.logo {
  height: 42px;
  margin: 16px;
}

.nav-logo {
  float: left;
  font-weight: 700;
  font-size: 24px;
}

.site-layout .site-layout-background {
  background: #fff;
}

header.cmcty-layout-header {
  height: var(--header-height);
  line-height: var(--header-height);

  .cmcty-typography {
    display: inline-flex;
    align-items: center;
  }
}

.header-sider {
  width: 100% !important;
  max-width: inherit !important;
  height: 50px;
  padding: 0 20px;
  line-height: 50px;

  .cmcty-typography > div {
    display: inline-flex;
    margin-top: 0;
    margin-right: 0;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.ant-table-body {
  scrollbar-color: auto;
}

.cmcty-table-body {
  scrollbar-color: auto;
}

.cmcty-table-body::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

.cmcty-table-body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cmcty-layout-footer {
  padding: 8px;
}

.user-action {
  padding-right: 120px;
}

.loading-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-cover {
  position: absolute;
  z-index: 200;
  background: #eeeeee;
  opacity: 0.5;
}

.cmcty-menu-dark .cmcty-menu-inline.cmcty-menu-sub {
  background: #1a4f99 !important;
}

.cmcty-menu-dark.cmcty-menu-dark:not(.cmcty-menu-horizontal) .cmcty-menu-item-selected {
  background: #619bc5 !important;
}

/* Flex */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.check-group-row {
  .cmcty-checkbox-group-item {
    width: 100%;
  }
}

.cmcty-transfer {
  justify-content: center;
}

.line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 控制行数 */
}

.font-size-12 {
  font-size: 12px;
}

.button-link-underline {
  span {
    text-decoration: underline;
  }
}

.mode-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 32px;
  margin-right: 15px;
  cursor: pointer;
}

.cmcty-table-cell:has(.highLight) {
  background: rgba(255, 0, 0, 0.3);
  animation: bg-blink 1s linear forwards;
}

.cmcty-modal-content {
  .virtual-grid {
    .virtual-table-cell {
      padding-top: 3px;
      padding-left: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-right: 1px solid #eeeeee;
      border-left: 1px solid #eeeeee;
    }

    .virtual-table-cell.virtual-table-cell-even {
      background-color: #f5f5f5af;
    }

    .virtual-table-cell.virtual-table-cell-odd {
      background-color: #ffffff;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bcbcbc;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }
}

.select-height-32 {
  .cmcty-select-selector {
    height: 32px;
  }
}

.cmcty-table-cell-scrollbar {
  display: none;
}

.row-dragging {
  &.light-green {
    background-color: rgb(238, 252, 238);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover {
      background-color: rgb(238, 252, 238);
    }
  }

  &.light-red {
    background-color: rgb(255, 228, 228);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover {
      background-color: rgb(255, 228, 228);
    }
  }
}

.project-setup-form {
  .cmcty-table-thead
    > tr
    > th:not(:last-child):not(.cmcty-table-selection-column):not(.cmcty-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .cmcty-table-cell {
    border-color: #ddd;
    border-right: solid 1px #ddd;
    border-bottom: solid 1px #ddd;

    &:first-child {
      border-left: solid 1px #ddd;
    }
  }

  th.cmcty-table-cell {
    border-top: solid 1px #ddd;
  }

  .cmcty-table.cmcty-table-small .cmcty-table-thead > tr > th {
    padding: 4px;
  }

  .cmcty-table-thead > tr:not(:nth-child(1)) {
    th {
      border-left: 0;
    }
  }

  .project-select {
    width: 100%;

    &.left {
      .cmcty-select-selector {
        text-align: left;
      }
    }

    &.right {
      .cmcty-select-selector {
        text-align: right;
      }
    }
  }
}

.pagination-style-initial {
  .cmcty-table-pagination {
    position: initial !important;
    display: flex !important;
  }
}

.position-relative {
  position: relative;
}

.cmcty-menu-dark .cmcty-menu-inline.cmcty-menu-sub {
  background: #a754b5;
}

.cmcty-menu-dark.cmcty-menu-dark:not(.cmcty-menu-horizontal) .cmcty-menu-item-selected {
  background: #c061c5;
}

.comparison-card-pop,
.project-info-pop {
  .pop-dl {
    display: flex;
    border-top: solid 1px #e9e9e9;
    margin: 0;
    width: 100%;
    justify-content: space-between;

    &:last-child {
      border-bottom: solid 1px #e9e9e9;
    }

    > * {
      padding: 8px;
      margin: 0;
    }

    > dt {
      text-align: left;
      min-width: 100px;
    }

    > dd {
      text-align: left;
    }
  }

  .grid-table {
    display: flex;

    .table-name,
    .table-value {
      flex: 1;
      div {
        border-top: solid 1px #e9e9e9;
        padding: 3px 8px;
      }
    }

    .table-value {
      div {
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 400px;
      }

      .number {
        text-align: right;
      }

      .update-item {
        color: var(--update-color);
      }
    }
  }
}

.cmcty-layout-has-sider .menu-sider {
  .cmcty-layout-sider-children {
    .logo {
      margin: 6px;
    }
  }
}


.cmcty-menu.cmcty-menu-root {
  li div.cmcty-menu-submenu-title {
    height: 20px;
    line-height: 20px;
  }

  .cmcty-menu.cmcty-menu-sub {
    li {
      height: 18px;
      margin: 0;
      line-height: 18px;
      margin-left: 3px;
      span {
        line-height: 18px;
      }
    }
  }
}

.strategy-matrix-legend {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  div {
    display: flex;
    align-items: center;
    margin-right: 5px;
    span {
      &:first-child {
        display: block;
        width: 14px;
        height: 14px;
        background: red;
        border-radius: 20%;
      }
      &:last-child {
        padding-left: 5px;
      }
    }
  }
}

.version-button:disabled {
  background-color: #f5f5f5;
}

.square-marker span {
  position: relative;
}
.square-marker span::before,
.square-marker span::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: rotate(45deg);
  content: '';
}
.square-marker span::before {
  top: 1px;
  left: 6px;
  width: 1px; /* 直线宽度 */
  height: 100%;
}
.square-marker span::after {
  left: 2px;
  width: 1px; /* 直线宽度 */
  height: 100%;
}
.no-borderd {
  .cmcty-descriptions-row {
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
  }
  .cmcty-descriptions-view {
    border: none !important;
  }
}
.deep-border.cmcty-descriptions {
  .cmcty-descriptions-view {
    border: 1px solid #aaa;
  }
}
.descriptions-content-padding-0-16 {
  .cmcty-descriptions-item-content {
    padding: 0 16px !important;
  }
}

.text-left {
  .cmcty-select-selection-item {
    text-align: left !important;
  }
}
