div.aipmc-authenticator {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  background-image: url(/public/assets/backgroundpic.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
}

div.aipmc-authenticator > div[data-amplify-container] {
  opacity: 85%;
  width: 450px;
}
div.aipmc-authenticator div[data-amplify-router] {
  border-radius: 10px;
}

div.aipmc-authenticator form input {
  font-size: large;
}
div.aipmc-authenticator form button {
  font-size: large;
}
div.aipmc-authenticator button {
  font-size: medium;
}
div.aipmc-authenticator div {
  font-size: medium;
}
.amplify-button--primary {
  background-color: #1a4f99 !important;
}
.amplify-button--link {
  color: #1a4f99 !important;
}
