.el-backtop {
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  color: #fff;
  font-size: 20px;
  background-color: #3299D9;
  border-radius: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
