.matching-result-page {
  .cmcty-table-thead > tr > th {
    text-align: center;
  }

  .cmcty-table.cmcty-table-small .cmcty-table-tbody > tr > td {
    padding-right: 16px;
  }

  .cmcty-table-cell.cmcty-table-cell-fix-left.cmcty-table-cell-fix-left-last.cmcty-table-cell-with-append {
    text-align: left;
  }

  .cmcty-table-cell {
    text-align: right;
  }

  .operation-container {
    padding: 10px 24px;
    background-color: white;
  }

  .cmcty-tabs-content.cmcty-tabs-content-top {
    height: 100%;
  }

  .table-alert-red td {
    font-weight: bolder;
  }
}
