/* stylelint-disable no-descending-specificity */
/* stylelint-disable indentation */
/* stylelint-disable selector-combinator-space-before */
/* stylelint-disable rule-empty-line-before */
.typical-kpi-table {
  --border-color: rgba(0, 0, 0, 0.2);
  .cmcty-table-thead {
    th {
      font-weight: bolder;
    }
  }
  .cmcty-table.cmcty-table-bordered
    > .cmcty-table-container
    > .cmcty-table-header
    > table
    > thead
    > tr:not(:last-child)
    > th {
    border-bottom: 1px solid var(--border-color);
  }
  .cmcty-table.cmcty-table-bordered
    > .cmcty-table-container
    > .cmcty-table-header
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid var(--border-color);
  }
  .cmcty-table.cmcty-table-bordered
    > .cmcty-table-container
    > .cmcty-table-body
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid var(--border-color);
  }
  .cmcty-table-thead > tr > th {
    border-bottom: 1px solid var(--border-color);
  }
  .cmcty-table-tbody > tr > td {
    border-bottom: 1px solid var(--border-color);
  }

  .cmcty-table-cell-row-hover {
    background-color: rgb(207 234 247) !important;
  }

  .cmcty-table-cell {
    text-align: right;
  }

  .cmcty-table-cell.cmcty-table-cell-fix-left.cmcty-table-cell-fix-left-last.cmcty-table-cell-with-append{
    text-align: left;
  }
}
