.action {
  tr.light-green {
    background-color: rgb(238, 252, 238);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-column-sort {
      background-color: rgb(238, 252, 238);
    }
  }

  tr.light-red {
    background-color: rgb(255, 228, 228);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-column-sort {
      background-color: rgb(255, 228, 228);
    }
  }

  tr.light-yellow {
    background-color: rgb(254, 251, 179);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-column-sort {
      background-color: rgb(254, 251, 179);
    }
  }

  tr.light-gray {
    background-color: rgb(230, 230, 230);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-column-sort {
      background-color: rgb(230, 230, 230);
    }
  }

  tr.light-dark-gray {
    background-color: rgb(245, 245, 245);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-column-sort {
      background-color: rgb(245, 245, 245);
    }
  }
  th.cmcty-table-cell {
    height: 50px;
    padding: 12px 12px;
    font-weight: bold;
    text-align: center;
  }
  td.cmcty-table-cell {
    height: 42px;
    padding: 4px 8px !important;
  }
  .no-validate-message-form {
    .cmcty-form-item-explain {
      display: none;
    }
  }
  .align-left {
    text-align: left;
  }
  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .action-cell {
    height: 39px;
  }
  .action-card {
    width: 100%;
    background: #efefef;
    border-radius: 6px;
  }
  .action-card-title {
    padding: 0.375rem 0.75rem;
    .CardTitle-title {
      font-size: 14px;
    }
    .CardTitle-subtitle {
      font-size: 12px;
    }
  }
  .cmcty-form-item-control-input {
    min-height: 0;
  }
}
