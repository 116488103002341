.tooltip-area {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
  display: none;
}

.popover-icon {
  display: block;
  margin-bottom: 6px;
  padding: 5px;
  color: #fff;
  font-size: 12px;
  background-color: #ccc;
  border-radius: 20px;
}

.summary-kpi-card {
  padding: 0;
  &:hover {
    .tooltip-area {
      display: block !important;
    }
  }
}

.summary-kpi-modal {
  .chart-table-item {
    flex: 1;
    font-size: 18px;
    &:first-child {
      flex: none;
      align-items: center;
      width: 50px;
      .number-icon {
        display: block;
        width: 20px;
        height: 20px;
        margin: 4px auto 0;
        margin-top: 6px;
        color: #fff;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }
}

.chart-y-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  writing-mode: vertical-lr;
}

.chart-x-label {
  width: 200px;
  text-align: center;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.chart-table-item {
  flex: 1;
  font-weight: bolder;
  &:first-child {
    flex: none;
    align-items: center;
    width: 30px;
    .number-icon {
      display: block;
      width: 15px;
      height: 15px;
      margin: 6px auto 0;
      margin-top: 6px;
      color: #fff;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      background-color: #000;
      border-radius: 50%;
    }
  }
}

.chart-table {
  display: flex;
  justify-content: center;
  height: 30px;
  margin-left: 0px;
  padding: 1px 2px;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.06);
  &:first-child {
    margin-top: 3px;
  }
  &.header {
    background-color: #fafafa;
  }
}

.list-card-header {
  padding: 0;
  position: relative;
}

.list-card-title {
  display: block;
  margin-bottom: 0;
  color: #555;
  font-weight: bold;
  font-size: 18px;
}

.list-card-subtitle {
  top: 4px;
  font-size: 14px;
  .cmcty-btn {
    margin-left: 1px;
    border-radius: 0;
  }
}

.cmcty-modal-title {
  font-size: 20px;
}
