.comparison {
  .operation-container {
    margin-bottom: 6px;
    padding: 10px;
    background-color: white;
  }

  .diff-graphs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;

    > * {
      position: relative;
      min-height: 340px;
      padding: 12px;
      padding: 12px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      inline-size: calc(100% / 4);
    }
  }

  .diff-container {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 6px;
  }

  .compare-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    inline-size: calc((100% - 50px) / 5);
    block-size: 100%;
  }

  .compare-item-resource {
    display: flex;
    flex: 1;
    flex-direction: column;
    inline-size: calc(100% / 3);
  }

  .compare-item-resource-label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-weight: bold;
    background: #fff;
  }

  .item-card {
    position: relative;
    background-color: #fff;

    &.card-highlight {
      box-shadow: 0 0 6px 2px var(--card-shadow);
    }

    ul,
    li {
      padding: 0;
      list-style: none;
    }

    li {
      padding: 6px 16px;
      &:not(:first-child) {
        border-top: solid 1px #e9e9e9;
      }

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }

      &.list-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.great {
          color: var(--item-great);
        }

        &.less {
          color: var(--item-less);
        }
      }
    }

    .card-title {
      font-weight: bold;
    }

    .card-diff {
      padding: 2px 12px;
      color: white;
      font-weight: bold;
      background-color: var(--diff-color);
      border-radius: 4px;
    }

    .cmcty-tree {
      background-color: transparent;
    }

    .cmcty-tree-switcher {
      background-color: transparent;
    }

    .cmcty-card-body {
      height: var(--body-height);
      overflow: auto;

      &:has(.cmcty-spin) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cmcty-card-body::-webkit-scrollbar {
      width: 8px;
      height: 7px;
    }

    .cmcty-card-body::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 5px;
    }
  }

  .cmcty-tabs-nav {
    margin-bottom: 6px;
    .cmcty-tabs-tab.cmcty-tabs-tab-active .cmcty-tabs-tab-btn {
      font-weight: bolder;
    }
  }

  .phase-migrate {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    font-size: 12px;
    gap: 1px;
    margin-top: 26px;
    max-width: 100%;
  }

  .phase-item {
    padding: 6px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    flex-direction: column;
    user-select: none;

    &.filter {
      cursor: pointer;
    }

    &.backward {
      background-color: var(--backward-color);
    }

    &.forward {
      background-color: var(--forward-color);
    }

    &.title {
      background-color: var(--title-color);
    }

    &.retain {
      background-color: var(--retain-color);
    }

    &.angle {
      position: relative;
      background: linear-gradient(
        to top right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) calc(50% - 1px),
        rgba(0, 0, 0, .5) 50%,
        rgba(255, 255, 255, 0) calc(50% + 1px),
        rgba(255, 255, 255, 0) 100%
      ), var(--title-color);

      > span {
        position: absolute;
      }

      > .current {
        right: 4px;
        top: 4px;
      }

      > .target {
        left: 4px;
        bottom: 4px;
      }
    }
  }
}
