.operation-history-page {
  .record-table {
    .cmcty-table-thead>tr>th {
      text-align: center;
    }
  }

  .operation-container {
    margin-bottom: 6px;
    padding: 10px;
    background-color: white;
  }
}